<template>
  <div class="aboutUsPage bgfff">
    <div class="app h5MaxWidth">
      <header>
        <div class="header h5MaxWidth clear display alignCenter">
          <div
            class="back display alignCenter justifyCenter"
            onclick="window.history.go(-1)"
          >
            <i class="iconfont icon-arrow-left"></i>
          </div>
          <h2 class="flex1">About US</h2>
          <div class="navskip" id="sideMenu"></div>
        </div>
      </header>
      <div class="main">
        <div class="about" v-html="info"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      info: "",
    };
  },
  components: {},
  methods: {},
  mounted() {
    this.getAjax("api/index/about", {}, "post").then((res) => {
      this.info = res.data.content;
    });
  },
};
</script>

<style scoped lang="less">
@charset "utf-8";

.about {
  padding: 10px;
  background-color: #fff;
}

.headline {
  margin: 0 0 20px;
}

.headline h3 {
  line-height: 1.2;
  font-size: 26px;
  color: var(--black0);
}

.textart {
  margin: 20px 0;
}

.textart h3 {
  font-size: 22px;
  color: var(--black0);
}

.textart h4 {
  margin: 10px 0 0;
  font-size: 18px;
  color: var(--black0);
}

.textart p {
  padding: 5px 0;
  font-size: 16px;
  color: var(--black1);
  line-height: 1.2;
}

.textart p b {
  margin: 0 10px 0 0;
  font-size: 16px;
  color: var(--black0);
}

.textart img {
  width: 100%;
}

.textart ul li {
  margin: 5px 0;
  padding: 5px 0;
}

.textart ul li h4,
.textart ul li p {
  margin: 0;
  padding: 0;
}

/* VIP Card */
.vipMSP {
  padding: 10px;
}

.vipContent ul > li {
  position: relative;
}

.vipCard {
  margin: 20px 0 0;
  padding: 10px 20px;
  background-color: #fff;
}

.vipCard .explain {
  margin: 0;
  padding: 0 40px 0 0;
}

.vipLevelMark {
  background-color: transparent;
}

.vipLevelMark .vip_ICON {
  width: 65px;
  height: initial;
}
</style>
